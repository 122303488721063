import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Col, Container, Row } from 'react-bootstrap';
import ButtonIcon from '../icon/buttonIcon';

const InstaFeed = () => {
  const instadata = useStaticQuery(graphql` 
    {
      allMarkdownRemark(
        sort: {fields: frontmatter___sort, order: DESC}
        filter: {frontmatter: { page: {eq: "instagram"}}}
        limit: 15
      ) {
        nodes {
          frontmatter {
            caption
            url
            thumbnail {
              childImageSharp {
                gatsbyImageData(formats: AUTO, width: 410, height: 410, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }    
    `);

  const instaposts = instadata.allMarkdownRemark.nodes    

  return (
    <section className="home-insta pt-0">
        <Container>
            <Row className="justify-content-center x">
                <Col md={8} className="text-center">
                    <h2 className="sub-title">Latest From Our Social Media</h2>
                </Col>
            </Row>
            <Row className="mt-lg-5">
                {instaposts.map((instapost) => (
                  <Col lg={4} className="pb-3">
                  <a
                      href={instapost.frontmatter.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="instaurl"
                  >
                      <GatsbyImage image={getImage(instapost.frontmatter.thumbnail)} alt={instapost.frontmatter.caption} />
                      <div className="overlay-insta">
                          <div className='ov-text'>
                              <span>View Post</span>
                          </div>
                      </div>
                  </a>
              </Col>
                ))}
            </Row>
            <div className="bottom-btn-wrapper d-flex justify-content-center">
              <a href="https://www.instagram.com/wowmakers/"  className="btn wowaction-btn" target='_blank' rel="noopener noreferrer">
                <ButtonIcon />
                <span>For more updates, check out Instagram page</span>
                </a>
            </div>            
      </Container>
    </section>
  );
};

export default InstaFeed;
