import React from "react"
import SeoHead from "../components/global/seoHead"
import Layout from "../components/global/layout"
import Seo from "../components/global/seo"
import ScrollIndicationHeightZero from "../components/global/scrollIndicationHeightZero"
import HomeMain from "../components/home/homeMain"
import HomeBlogList from "../components/home/homeBlogList"
import CompanyLogo from "../components/global/companyLogo"
import AboutWork from "../components/global/aboutWork"
import HomeCaseStudiesListing from "../components/home/homeCaseStudiesListing"
import HomeWorkCategoryUserExpDesign from "../components/home/homeWorkCategoryUserExpDesign"
import HomeWorkCulture from "../components/home/homeWorkCulture"
import HomeTestimonials from "../components/home/homeTestimonials"
import Awards from "../components/careers/awards"
import HomeForm from "../components/home/homeForm"
import InstaFeed from "../components/global/instaFeed"
import DirectContact from "../components/global/directContact"

import featuredImage from "../../static/home/wowmakers.jpeg"

import "../sass/pages/home.scss"
import "../sass/global/work-category.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={featuredImage}
      title="WowMakers® | Design Driven Engineering"
      description="We're a 12-year old digital agency specialised in User Experience Design, Mobile & Web Engineering and Explainer Videos."
    />
  </> 
)

const Home = () => {
  return (
    <Layout>
      <Seo 
          bclass="home"
          bid="home"
      />
      <ScrollIndicationHeightZero />
      <HomeMain />
      <CompanyLogo />
      <AboutWork />
      <HomeWorkCategoryUserExpDesign />
      <HomeCaseStudiesListing />
      <HomeTestimonials />
      <Awards />
      <HomeBlogList />
      <InstaFeed />
      <HomeWorkCulture />
      <HomeForm page="home"/>
      <DirectContact />
    </Layout>
  )
}

export default Home
