import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import ButtonIcon from "../icon/buttonIcon"
import HomeBlog from "./homeBlog"

const HomeBlogList = () => {
  const data = useStaticQuery(graphql`
    {
      allWpPost(sort: { fields: date, order: DESC }, limit: 3, filter: {relPost: {featureHomePage: {eq: true}}}) {
        nodes {
          title
          uri
          slug
          content
          date(formatString: "MMM DD, YYYY")
          relPost {
            externalLink
          }
          categories {
            nodes {
              name
              uri
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: AUTO, width: 386, height: 216, placeholder: BLURRED)
                }
              }
            }
          }          
        }
      }
    }
  `)
  return (
    <section className="learning-insight pt-0">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">Learning and insights from our work</h2>
            <p className="sub-desc">
              No matter how experienced you are, there is always something new
              to learn from every project. We are happy to share some of those
              learning through our blog. Read on!
            </p>
          </Col>
        </Row>
        <Row>
          {data.allWpPost.nodes.map((node, index) => (
            <HomeBlog key={node.id} node={node} index={index} />
          ))}                       
        </Row>
        <div className="bottom-btn-wrapper d-flex justify-content-center">
          <Link to="/blog/" className="btn wowaction-btn">
            <ButtonIcon />
            <span>Thirst for knowledge? Read more articles</span>
          </Link>
        </div>
      </Container>
    </section>
  )
}

export default HomeBlogList
