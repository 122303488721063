import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import MainLogo from "../global/mainLogo"
import "../../sass/global/main.scss"
import ButtonIcon from "../icon/buttonIcon"

const HomeMain = () => {
  return (
    <div className="main">
      <Container>
        <Row>
          <Col md={12} lg={7} xl={5}>
            <div className="intro">
              <h1 className="title">
                Design Driven Engineering
              </h1>
              <p className="description">
                We work with ambitious organisations, combine our design thinking, digital expertise and creativity to build something great together.
              </p>
              <a href="/#CQL" className="btn wowaction-btn drift-chat-popup">
                <ButtonIcon />
                <span>Have a project in mind? Chat with us</span>
              </a>
            </div>
          </Col>
          <MainLogo />
        </Row>
      </Container>
    </div>
  )
}

export default HomeMain
