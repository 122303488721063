import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ButtonIcon from "../icon/buttonIcon"
import "../../sass/global/work-culture.scss"

const HomeWorkCulture = () => {
  return (
    <section className="work-culture">
      <Container>
        <Row>
          <Col md={8} lg={5}>
            <div className="content">
              <h2 className="title">
                Our team is friendly, <br />
                honest and{" "}
                <strong>
                  great fun to work with!
                </strong>
              </h2>
              <p className="description">
                We are very particular about maintaining a free, liberal, equal
                and open work culture. At WowMakers, meritocracy always wins!
              </p>
              <Link to="/about/" class="btn wowaction-btn">
                <ButtonIcon />
                <span>Know more about our team &amp; culture</span>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HomeWorkCulture
