import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ButtonIcon from "../icon/buttonIcon"

const HomeCaseStudiesListing = () => {
  return (
    <section className="home-cs-listing">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title" style={{ color: "#fff" }}>
              Unlocking possibilities. One project at a time
            </h2>
            <p className="sub-desc" style={{ color: "#fff" }}>
              Take a glimpse through Case Studies of our recent projects and
              understand how we’ve helped these organisations to delight their
              customers
            </p>
          </Col>
        </Row>
        <Row className="wrapper-main">
          <Col lg={4} className="d-md-none">
            <Link to="/case-studies/fifa-arab-cup-2021/" className="home-cs-link last">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/case-studies/fifa-arab-cup-2021/fifa-ac-web-app.png"
                  alt="Fifa Arab Cup 2021 UX/UI | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                />
              </div>
              <div className="hcs-content">
                <span className="section-tag">Sports<span className="seperator"><span>.</span></span>UX/UI Design</span>
                <div className="cs-header">
                  <h3>
                    How we designed Fan ID Platform for Fifa Arab Cup 2021
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read Case Study</span>
                </div>
              </div>
            </Link>
          </Col>          
          <Col lg={4} className="mt-md-0 mt-3">
            <Link to="/case-studies/trax-mobile-app/" className="home-cs-link first">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/case-studies/trax-mobile-app/cs-trax-bike-shop-app.png"
                  alt="Trax Mobile App UX/UI Case Study | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content">
                <span className="section-tag">eCommerce<span className="seperator"><span>.</span></span>UX/UI Design</span>
                <div className="cs-header">
                  <h3>
                    Reimagining an eCommerce experience for e-bike enthusiasts with TRAX
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read Case Study</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-0 mt-3">
            <Link to="/case-studies/bfc-payments/" className="home-cs-link middle">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/case-studies/bfc-payments/bfc-home-page-thumbnail.png"
                  alt="Golazo Case Study"
                  placeholder="blurred"
                  width={1014}
                  height={708}
                />
              </div>
              <div className="hcs-content">
                <span className="section-tag">Fintech<span className="seperator"><span>.</span></span>UX/UI Design</span>
                <div className="cs-header">
                  <h3>
                    How our UI/UX team made BFC Payment app the trusted choice in Money transfer, Currency exchange and Bill payments.
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read Case Study</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-0 mt-3 d-md-flex d-none">
            <Link to="/case-studies/fifa-arab-cup-2021/" className="home-cs-link last">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/case-studies/fifa-arab-cup-2021/fifa-ac-web-app.png"
                  alt="Fifa Arab Cup 2021 UX/UI | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                />
              </div>
              <div className="hcs-content">
                <span className="section-tag">Sports<span className="seperator"><span>.</span></span>UX/UI Design</span>
                <div className="cs-header">
                  <h3>
                    How we designed Fan ID Platform for Fifa Arab Cup 2021
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read Case Study</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
              <Link to="/case-studies/social-delivery-system/" className="home-cs-link first">
              <div className="hcs-img">
                  <StaticImage
                  src="../../images/case-studies/social-delivery-system/social-delivery-system.jpg"
                  alt="Social Delivery System UX/UI Case Study | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                  />
              </div>
              <div className="hcs-content">
                  <span className="section-tag">Logistics<span className="seperator"><span>.</span></span>UX/UI Design</span>
                  <div className="cs-header">
                  <h3>
                      Unleashing the Power of Community-Driven Package Delivery with Raaven
                  </h3>
                  </div>
                  <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read Case Study</span>
                  </div>
              </div>
              </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/case-studies/electric-car-commute-app" className="home-cs-link middle">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/case-studies/electric-car-commute-app/rivian-electric-car-commute-app.jpg"
                  alt="TBX UX/UI Case Study | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                />
              </div>
              <div className="hcs-content">
                <span className="section-tag">Automotive<span className="seperator"><span>.</span></span>UX/UI Design</span>
                <div className="cs-header">
                  <h3>
                    Revolutionizing Electric Vehicle Commuting with our Intuitive UX/UI Design
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read Case Study</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/case-studies/ashghals-travel-app/" className="home-cs-link last">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/case-studies/ashghals-travel-app/cs-ashghals-travel-app.png"
                  alt="Ashghals Travel App UX/UI Case studies | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                />
              </div>
              <div className="hcs-content">
                <span className="section-tag">Government<span className="seperator"><span>.</span></span>UX/UI Design</span>
                <div className="cs-header">
                  <h3>
                    How we helped the Qatari Government design a Travel Information System
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read Case Study</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/case-studies/cult-transform/" className="home-cs-link first">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/case-studies/cult-transform/cs-cult-transform.jpg"
                  alt="Cult Transform Explainer Video Case Study | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content">
                <span className="section-tag">Fitness & Wellness<span className="seperator"><span>.</span></span>Explainer Video</span>
                <div className="cs-header">
                  <h3>
                    How we helped Cult Fitness to guide their users on rewarding fitness journeys through their habit-coaching program
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read Case Study</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/case-studies/icici-bank/" className="home-cs-link middle">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/case-studies/icici-bank/cs-icici-bank.jpg"
                  alt="ICICI Bank Explainer Video Case Study | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                />
              </div>
              <div className="hcs-content">
                <span className="section-tag">Banking<span className="seperator"><span>.</span></span>Explainer Video</span>
                <div className="cs-header">
                  <h3>
                    How our explainer video helped ICICI bank to establish their latest recruitment chatbot
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read Case Study</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/case-studies/gwc-logistics/" className="home-cs-link last">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/case-studies/gwc-logistics/cs-gwc-logistics-1.jpg"
                  alt="GWC Logistics Explainer Video Case study| WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                />
              </div>
              <div className="hcs-content">
                <span className="section-tag">Logistics<span className="seperator"><span>.</span></span>Explainer Video</span>
                <div className="cs-header">
                  <h3>
                    How we helped Qatar’s leading logistics provider market its grand solutions with creative explainer videos
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read Case Study</span>
                </div>
              </div>
            </Link>
          </Col>                    
        </Row>
        <div className="bottom-btn-wrapper d-flex justify-content-center">
          <Link to="/case-studies/" className="btn wowaction-btn">
            <ButtonIcon />
            <span>Interesting? Read more case studies</span>
          </Link>
        </div>
      </Container>
    </section>
  )
}

export default HomeCaseStudiesListing
