import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const HomeWorkCategoryUserExpDesign = () => {
  return (
    <section className="work-category-in-details user-exp-design">
      <Container>
        <Row className="portfolio-content-home">
          <Col xl={8} lg={10} className="text-center text-md-left">
            <h2>Our Services</h2>

            <Col md={12} className="mt-5">
              <Row>
                <Col md={6} sm={6}>
                  <h3>Engineering</h3>
                  <ul className="item">
                    <li>Digital Transformation</li>
                    <li>Enterprise & Government Solutions</li>
                    <li>Mobile & Web Applications</li>
                    <li>Artificial Intelligence & Machine Learning</li>
                  </ul>
                </Col>
                <Col md={6} sm={6}>
                  <h3>User Experience</h3>
                  <ul className="item">
                    <li>User Research</li>
                    <li>User Experience Design</li>
                    <li>User Interface Design</li>
                    <li>UX Audit </li>
                  </ul>
                </Col>
                <Col md={6} sm={6}>
                  <h3>Creative Content</h3>
                  <ul className="item item-mb-desktop-none">
                    <li>Animated Explainer Video</li>
                    <li>Whiteboard Video</li>
                    <li>Corporate Video</li>
                    <li>Marketing Video</li>
                  </ul>
                </Col>
                <Col md={6} sm={6}>
                  <h3>Branding & Marketing</h3>
                  <ul className="item item-mb-desktop-none item-bottom-m">
                    <li>Brand Identity Design</li>
                    <li>Brand Positioning</li>
                    <li>Marketing Strategy</li>
                    <li>Marketing Content</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HomeWorkCategoryUserExpDesign
