import React from "react"

import { Col, Container, Row } from "react-bootstrap"


const Awards = () => {
  return (
    <section className="about-work-culture pt-0">
        <Container>
            <Row className="justify-content-center">
                <Col md={12} className="text-center">
                    <h2 className="sub-title">Awards & Recognitions</h2>                    
                </Col>
                <Col lg={12} className="pt-5">
                    <Row>
                        <Col lg={3} xs={6} className="mb-5 text-center">
                            <img src="/about-us/awards-1.svg" width="362" height="248" alt="WowMakers - Clutch Top B2B Services India 2022" className="img-fluid"/>
                        </Col>
                        <Col lg={3} xs={6} className="mb-5 text-center">
                            <img src="/about-us/awards-2.svg" width="362" height="248" alt="WowMakers - Agency Spotter Top UX Agencies 2021" className="img-fluid"/>
                        </Col>
                        <Col lg={3} xs={6} className="mb-5 text-center">
                            <img src="/about-us/awards-3.svg" width="362" height="248" alt="WowMakers - The Manifest Top 100 UX Agencies India 2020" className="img-fluid"/>
                        </Col>
                        <Col lg={3} xs={6} className="mb-5 text-center">
                            <img src="/about-us/awards-4.svg" width="362" height="248" alt="WowMakers - Clutch Best UX Agencies 2020" className="img-fluid"/>
                        </Col>
                        <Col lg={3} xs={6} className="mb-lg-0 mb-5 text-center">
                            <img src="/about-us/awards-5.svg" width="362" height="248" alt="WowMakers - Most Promising Company 2017" className="img-fluid"/>
                        </Col>
                        <Col lg={3} xs={6} className="mb-lg-0 mb-5 text-center">
                            <img src="/about-us/awards-6.svg" width="362" height="248" alt="WowMakers - Star Young Entrepreneur 2015" className="img-fluid"/>
                        </Col>
                        <Col lg={3} xs={6} className="text-center">
                            <img src="/about-us/awards-7.svg" width="362" height="248" alt="WowMakers -Top 5 Promising Startup 2012" className="img-fluid"/>
                        </Col>                                                                                                                                                                                                                                                                                
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default Awards
