import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Col } from "react-bootstrap"
import '../../sass/blog/blog-home.scss'

const readingTime = require('reading-time');
const options = { wordsPerMinute: 400 }

const HomeBlog = ({node, index}) => {
    const blogUrl = node.relPost.externalLink? node.relPost.externalLink: node.uri
    return (
    <Col md={6} lg={4}>              
    <article className="blog-article">
      <div className="blog-link" id={'test-'+index}>
        <div className="artile-image">
          <Link to={blogUrl} className="thumb-url" id={'thumnb-url-'+index} data-url={'#title-url-'+index}>
            <GatsbyImage image={getImage(node.featuredImage.node.localFile)} alt={ node.featuredImage.node.altText } />
          </Link>
        </div>
        <div className="blog-meta">
          <time dateTime={node.date}>{node.date}</time>
          <span>
            <svg width="24" height="10" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5.7998" cy="5" r="4" stroke="#B8B8B8" stroke-width="1.59999" stroke-linecap="round"></circle>
                <path d="M9.65337 2.24106C9.23422 2.10134 8.78117 2.32787 8.64145 2.74702C8.50173 3.16617 8.72826 3.61922 9.14741 3.75894L9.65337 2.24106ZM14.4534 3.75894C14.8725 3.61922 15.099 3.16617 14.9593 2.74702C14.8196 2.32787 14.3666 2.10134 13.9474 2.24106L14.4534 3.75894ZM11.8004 3.8L11.5474 4.55894C11.7116 4.61368 11.8892 4.61368 12.0534 4.55894L11.8004 3.8ZM9.14741 3.75894L11.5474 4.55894L12.0534 3.04106L9.65337 2.24106L9.14741 3.75894ZM12.0534 4.55894L14.4534 3.75894L13.9474 2.24106L11.5474 3.04106L12.0534 4.55894Z" fill="#B8B8B8"></path> <path d="M22.5996 5.79922L22.5996 4.19922" stroke="#B8B8B8" stroke-width="1.59999" stroke-linecap="round"></path>
                <path d="M1 5.79922L1 4.19922" stroke="#B8B8B8" stroke-width="1.59999" stroke-linecap="round"></path>
                <circle cx="17.7998" cy="5" r="4" stroke="#B8B8B8" stroke-width="1.59999" stroke-linecap="round"></circle> </svg>
            <span className="span-reading-time">
              {
              readingTime(node.content, options).text
              }
            </span>
        </span>
        <Link to={node.categories.nodes[0]['uri']} activeClassName="active">{node.categories.nodes[0]['name']}</Link>                    
        </div>
        <div class="blog-meta-content">
          <Link to={blogUrl} className="title-url" id={'title-url-'+index} data-url={'#thumnb-url-'+index}>
            <h2>{node.title}</h2>
          </Link>
        </div>                  
      </div>
    </article>
    </Col>
    )    
}

export default HomeBlog