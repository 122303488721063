import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "../../sass/global/about-work.scss"

const AboutWork = () => {
    return (
        <section class="wow-work">
            <Container>
                <Row className="align-items-center">
                    <Col sm={6}>
                        <h2 className="wmw-left-content mobile-margin-left-right">
                            WowMakers is a digital agency specialized in User Experience Design, Mobile & Web Engineering and Explainer Videos.
                        </h2>                        
                    </Col>
                    <Col sm={6}>
                        <p className="wmw-right-content mobile-margin-left-right">
							In the last <strong>12</strong> years, we have successfully delivered <strong>328 </strong>
							projects for <strong>147</strong> clients across <strong>12</strong> verticals from <strong>6</strong> continents, 
							by our <strong>38</strong> member team working in <strong>4</strong> countries.							
						</p>                        
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AboutWork